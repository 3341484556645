export const environment = {
    baseUrl: 'https://stage.api.jigle.live/api/v2',
    production: false,
    apiUrl: 'https://stage.api.jigle.live/api/v2',
    googleClientId: '64027380729-bctbr02eb868kp6fpsbbb047pu8l6lmk.apps.googleusercontent.com',
    socketUrl: 'https://stage.ws.jigle.live',
    agoraToken: '94bae0fa20194bf6b58e636d6d115484',
    appKey: 'iKSGafT4FKwtTHIsm30hhmGCPHwuYFIH',
    amplitudeApiKey: 'af592904e4af1760181bdd76c5c407f9',
    facebookAppId: '275979356819918',
};
